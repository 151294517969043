import React, { Component } from 'react';
import '../App.css';

class LoveMatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image:"assets/lovers/young-f0.jpg",
      meeting:"A meeting has been arranged for 2pm Tuesday, at Brian's Bakery on 2nd and Main street. Please do not be late, and dress appropriately.",
      nameFirst:"Amanda",
      nameLast:"Sohno",
      personality: "A quiet, intelligent, thinker working towards a masters degree in applied philosophy."
    }

    this.question0 = localStorage.getItem("question0");
    this.question1 = localStorage.getItem("question1");
    this.question2 = localStorage.getItem("question2");
    this.question3 = localStorage.getItem("question3");
    this.question4 = localStorage.getItem("question4");
    this.question5 = localStorage.getItem("question5");
    this.question6 = localStorage.getItem("question6");
    this.question7 = localStorage.getItem("question7");

    this.picturesMaleElderly   = ["assets/lovers/elderly-m0.jpg", "assets/lovers/elderly-m1.jpg", "assets/lovers/elderly-m2.jpg", "assets/lovers/elderly-m3.jpg", "assets/lovers/elderly-m4.jpg"];
    this.picturesMaleOlder     = ["assets/lovers/older-m0.jpg", "assets/lovers/older-m1.jpg", "assets/lovers/older-m2.jpg", "assets/lovers/older-m3.jpg", "assets/lovers/older-m4.jpg", ];
    this.picturesMaleAdult     = ["assets/lovers/adult-m0.jpg", "assets/lovers/adult-m1.jpg", "assets/lovers/adult-m2.jpg", "assets/lovers/adult-m3.jpg", "assets/lovers/adult-m4.jpg", "assets/lovers/adult-m5.jpg"];
    this.picturesMaleYoung     = ["assets/lovers/young-m0.jpg", "assets/lovers/young-m1.jpg", "assets/lovers/young-m2.jpg", "assets/lovers/young-m3.jpg", "assets/lovers/young-m4.jpg", "assets/lovers/young-m5.jpg", "assets/lovers/young-m6.jpg", "assets/lovers/young-m7.jpg"];
    this.picturesFemaleElderly = ["assets/lovers/elderly-f0.jpg", "assets/lovers/elderly-f1.jpg", "assets/lovers/elderly-f2.jpg"];
    this.picturesFemaleOlder   = ["assets/lovers/older-f0.jpg", "assets/lovers/older-f1.jpg", "assets/lovers/older-f2.jpg", "assets/lovers/older-f3.jpg", "assets/lovers/older-f4.jpg", "assets/lovers/older-f5.jpg", "assets/lovers/older-f6.jpg"];
    this.picturesFemaleAdult   = ["assets/lovers/adult-f0.jpg", "assets/lovers/adult-f1.jpg", "assets/lovers/adult-f2.jpg", "assets/lovers/adult-f3.jpg", "assets/lovers/adult-f4.jpg", "assets/lovers/adult-f5.jpg", "assets/lovers/adult-f6.jpg", "assets/lovers/adult-f7.jpg"];
    this.picturesFemaleYoung   = ["assets/lovers/young-f0.jpg", "assets/lovers/young-f1.jpg", "assets/lovers/young-f2.jpg", "assets/lovers/young-f3.jpg", "assets/lovers/young-f4.jpg", "assets/lovers/young-f5.jpg", "assets/lovers/young-f6.jpg", "assets/lovers/young-f7.jpg", "assets/lovers/young-f8.jpg", "assets/lovers/young-f9.jpg", ];

    this.androNames = ["Kelly", "Lyndsey", "Courtney", "Sam", "Taylor", "Steph"];
    this.femaleNames = ["Jennifer", "Isabelle", "Chloe", "Rebecca", "Mary", "Samantha", "Krystal", "Mercedez", "Amanda"];
    this.maleNames = ["John", "Brian", "Micheal", "Kevin", "Robert", "Devin", "Kyle", "Adam", "Shaun", "Mathew"];
    this.lastName = ["Smith", "Fields", "Brown", "Wykoff", "Vasconez", "McIlly", "Zucker", "Phelp", "Kelly", "Ashton", "West", "Butcher"]
    this.nameGender = this.androNames;

    this.personality =[
      "A passionate lover that will drive you crazy in the best way possible.",
      "Quiet and very loving. Prepare to be considered, but don't forget to always be there for them.",
      "Thinks very highly of themselves and you, but do not disappoint.",
      "A very comfortable person that will make you laugh and fill you with love.",
      "A very diligent worker. Will have less time for you, but extremely loyal and loving.",
      "This person is so much fun. Not very responsible, but will take you on the adventure of your life.",
      "This rising artist will show you worlds of love and inspiration; along with extreme emotions.",
      "A quiet, intelligent, thinker working towards a masters degree in applied philosophy.",
      "They lack a flashy personality, but hold you with incredible conversation, love, and support."
    ]

  }

  componentDidMount() {
    //CALCULATE LOVE IF FIRST ELSE USE EXISTANT DATA
    console.log( localStorage.getItem("nameFirst") );
    if ( localStorage.getItem("nameFirst") !== null ) {

      let image = localStorage.getItem("image");
      let meeting = localStorage.getItem("meeting");
      let nameFirst = localStorage.getItem("nameFirst");
      let nameLast = localStorage.getItem("nameLast");
      let personality = localStorage.getItem("personality");

      console.log(image + meeting);

      this.setState({image: image});
      this.setState({meeting: meeting});
      this.setState({nameFirst: nameFirst});
      this.setState({nameLast: nameLast});
      this.setState({personality: personality});

    } else {
      this.calculateLove();
    }

    //ACTIVATE ANIMATIONS && STAGE EXIT
    setTimeout(()=> {
      document.getElementById("calculatingMessage").classList.remove("blink0");
      document.getElementById("calculatingMessage").style.opacity="0";
      document.getElementById("searchCompleteMessage").style.opacity="1";
    }, 11000);

    setTimeout(()=> {
      document.getElementById("searchCompleteMessage").style.filter="blur(100px)";
      document.getElementById("logoBanner").style.filter="blur(100px)";
    }, 13000);

    setTimeout(()=> {
      document.getElementById("introItem").style.marginTop="0";
    }, 14000);

    setTimeout(()=> {
      document.getElementById("meetingDescription").style.opacity="1";
    }, 15000);

    setTimeout(()=> {
      document.getElementById("meetingDescription").style.filter="blur(100px)";
      document.getElementById("introItem").style.filter="blur(100px)";
    }, 24000);

    setTimeout(()=> {
      this.props.stage("video");
    }, 30000);

  }

  calculateLove() {
    if (this.question0[2] === "W") {
      this.nameGender = this.femaleNames;

      switch(this.question1) {
        case " -Young "     : this.setState({image: this.picturesFemaleYoung[this.randomInt(0, (this.picturesFemaleYoung.length - 1) )] }, ()=>{localStorage.setItem("image", this.state.image);});break;
        case " -Not Young " : this.setState({image: this.picturesFemaleAdult[this.randomInt(0, (this.picturesFemaleAdult.length - 1) )] }, ()=>{localStorage.setItem("image", this.state.image);});break;
        case " -Older "     : this.setState({image: this.picturesFemaleOlder[this.randomInt(0, (this.picturesFemaleOlder.length - 1) )] }, ()=>{localStorage.setItem("image", this.state.image);});break;
        case " -Elderly "   : this.setState({image: this.picturesFemaleElderly[this.randomInt(0, (this.picturesFemaleElderly.length - 1) )] }, ()=>{localStorage.setItem("image", this.state.image);});break;
        default : break;
      }
    }

    if (this.question0[2] === "M") {
      this.nameGender = this.maleNames;

      switch(this.question1) {
        case " -Young "     : this.setState({image: this.picturesMaleYoung[this.randomInt(0, (this.picturesMaleYoung.length - 1) )] }, ()=>{localStorage.setItem("image", this.state.image);});break;
        case " -Not Young " : this.setState({image: this.picturesMaleAdult[this.randomInt(0, (this.picturesMaleAdult.length - 1) )] }, ()=>{localStorage.setItem("image", this.state.image);});break;
        case " -Older "     : this.setState({image: this.picturesMaleOlder[this.randomInt(0, (this.picturesMaleOlder.length - 1) )] }, ()=>{localStorage.setItem("image", this.state.image);});break;
        case " -Elderly "   : this.setState({image: this.picturesMaleElderly[this.randomInt(0, (this.picturesMaleElderly.length - 1) )] }, ()=>{localStorage.setItem("image", this.state.image);});break;
        default : break;
      }
    }

    if ( (this.question0[2] === "E") || (this.question0[2] === "N") ) {

      if( this.randomInt(1, 2) > 1 ) {
        switch(this.question1) {
          case " -Young "     : this.setState({image: this.picturesMaleYoung[this.randomInt(0, (this.picturesMaleYoung.length - 1) )] }, ()=>{localStorage.setItem("image", this.state.image);});break;
          case " -Not Young " : this.setState({image: this.picturesMaleAdult[this.randomInt(0, (this.picturesMaleAdult.length - 1) )] }, ()=>{localStorage.setItem("image", this.state.image);});break;
          case " -Older "     : this.setState({image: this.picturesMaleOlder[this.randomInt(0, (this.picturesMaleOlder.length - 1) )] }, ()=>{localStorage.setItem("image", this.state.image);});break;
          case " -Elderly "   : this.setState({image: this.picturesMaleElderly[this.randomInt(0, (this.picturesMaleElderly.length - 1) )] }, ()=>{localStorage.setItem("image", this.state.image);});break;
          default : break;
        }
      } else {
        switch(this.question1) {
          case " -Young "     : this.setState({image: this.picturesFemaleYoung[this.randomInt(0, (this.picturesFemaleYoung.length -1) ) ] }, ()=>{localStorage.setItem("image", this.state.image);});break;
          case " -Not Young " : this.setState({image: this.picturesFemaleAdult[this.randomInt(0, (this.picturesFemaleAdult.length -1) ) ] }, ()=>{localStorage.setItem("image", this.state.image);});break;
          case " -Older "     : this.setState({image: this.picturesFemaleOlder[this.randomInt(0, (this.picturesFemaleOlder.length -1) ) ] }, ()=>{localStorage.setItem("image", this.state.image);});break;
          case " -Elderly "   : this.setState({image: this.picturesFemaleElderly[this.randomInt(0, (this.picturesFemaleElderly.length -1) ) ] }, ()=>{localStorage.setItem("image", this.state.image);});break;
          default : break;
        }
      }
    }

    let streets = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th"];
    let days = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th", "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th"];
    
    let d = new Date();
    let weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";

    let currentDay = d.getDate()
    let currentDayWeek = d.getDay();
    currentDay += 3;
    currentDayWeek += 3;
    if (currentDayWeek > 6) { currentDayWeek = (currentDayWeek - 7)} 
    if (currentDay > 28) { currentDayWeek = (currentDayWeek - 28)} 

    let time = this.randomInt(1, 6);
    let day = days[currentDay -1];
    let dayWeek = weekday[currentDayWeek];
    let street = streets[this.randomInt(0, streets.length)];

    this.setState({nameFirst : this.nameGender[this.randomInt(0, this.nameGender.length)] }, ()=>{localStorage.setItem("nameFirst", this.state.nameFirst);});
    this.setState({nameLast  : this.lastName[this.randomInt(0, this.lastName.length)] }, ()=>{localStorage.setItem("nameLast", this.state.nameLast);} );
    this.setState({personality : this.personality[this.randomInt(0, this.personality.length)] }, ()=>{localStorage.setItem("personality", this.state.personality);});
    this.setState({meeting : "A meeting has been arranged for " + time + "pm " + dayWeek + " the " + day + ", at Brian's Bakery on " + street + " and Main street. Please do not be late, and dress appropriately." }, ()=>{localStorage.setItem("meeting", this.state.meeting);});

  }

  randomInt(min, max){
    let number = Math.floor(Math.random() * (max - min) + min)
    return number;
  }

  render() {

    return (
      <div id="portal" className="videoPage perspective">
        <div className={"logoBanner"} id="logoBanner">
          <img src="assets/horizontal-logoDotless.png" id="headerLogo" />
        </div>
        <h1 className={"loveMatchData"}>00101100{this.question0}10111101</h1>
        <h1 className={"loveMatchData"}>01111110{this.question1}00001100</h1>
        <h1 className={"loveMatchData"}>10101001{this.question2}10111101</h1>
        <h1 className={"loveMatchData"}>00000000{this.question3}00100111</h1>
        <h1 className={"loveMatchData"}>01111100{this.question4}11101101</h1>
        <h1 className={"loveMatchData"}>00101101{this.question5}00001000</h1>
        <h1 className={"loveMatchData"}>10101100{this.question6}00100101</h1>
        <h1 className={"loveMatchData"}>10011100{this.question7}11001100</h1>
        <h1 id="calculatingMessage" className={"blink0"}>Calculating Love</h1>
        <h1 id="searchCompleteMessage">Your True Love Has Been Determined</h1>
        <div className="introItemPerspective">
          <div id="introItem" className="carousel__cell  butterflyCard introItem">
            <p> {this.state.nameFirst + " " + this.state.nameLast}</p>
            <div>
              <img src={this.state.image} />
            </div>
            <p className="description">{this.state.personality}</p>
          </div>
          <p className="meetingDescription" id="meetingDescription">{this.state.meeting}</p>
        </div>
      </div>
    );
  }
}

export default LoveMatch;
