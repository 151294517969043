import React, { Component } from 'react';
import '../App.css';

class ZeroCupid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkboxForm:false,
      instructions: "Hello and welcome to Zero Cupid.",
      question0:"man",
      question1:"woman",
      question2:"either",
      question3:"non-binary",
      submitButton:false,
      submitData: false,
      yesNo: false,
      yesNoAnswer: ""
    }

    this.answer = "";
    this.question = 0;

    this.condition0 = true;
    this.condition1 = false;
    this.condition2 = false;
    this.condition3 = false;
    this.condition4 = false;
    this.condition5 = false;
    this.condition6 = false;
    this.condition7 = false;
    this.condition8 = false;
    this.condition9 = false;

    this.instructionDelay = 1500;

  }

  componentDidMount() {

    setTimeout(()=> {
      this.instruct("A data solution to your desire for love.");
    }, 3000);

    setTimeout(()=> {
      this.askInitiate();
    }, 7000);

  }

  checkbox(id) {
    let checkBoxes = Array.from( document.getElementsByClassName("checkbox") );

    checkBoxes.forEach( (x) => { 
      if(x.id !== id) {x.checked=false}; 
      if(x.id === id) {this.answer = x.nextSibling.innerHTML;} 
      }
    )
  }

  askInitiate(){
    this.instruct("Would you like to find your true love?");
    setTimeout(()=> {
      this.setState({yesNo:true});
    }, this.instructionDelay);
  }

  dataPoint0Initiate(){
    this.instruct("Which are you most attracted to?");
    setTimeout(()=> {
      this.setState({checkboxForm:true});
      this.setState({submitButton:true});

      this.setState({question0:"Man"});
      this.setState({question1:"Woman"});
      this.setState({question2:"Either"});
      this.setState({question3:"Non-Binary"});
    }, this.instructionDelay);
  }

  dataPoint1Initiate(){
    this.instruct("What is your age range?");
    setTimeout(()=> {
      this.setState({checkboxForm:true});
      this.setState({submitButton:true});

      this.setState({question0:"Young"});
      this.setState({question1:"Not Young"});
      this.setState({question2:"Older"});
      this.setState({question3:"Elderly"});
    }, this.instructionDelay);
  }

  dataPoint2Initiate(){
    this.instruct("Where are you located");
    setTimeout(()=> {
      this.setState({checkboxForm:true});
      this.setState({submitButton:true});

      this.setState({question0:"North"});
      this.setState({question1:"South"});
      this.setState({question2:"East"});
      this.setState({question3:"West"});
    }, this.instructionDelay);
  }

  dataPoint3Initiate(){
    this.instruct("Which is the best color?");
    setTimeout(()=> {
      this.setState({checkboxForm:true});
      this.setState({submitButton:true});

      this.setState({question0:"Orange"});
      this.setState({question1:"Red"});
      this.setState({question2:"Pink"});
      this.setState({question3:"Purple"});
    }, this.instructionDelay);
  }

  dataPoint4Initiate(){
    this.instruct("How smart are you?");
    setTimeout(()=> {
      this.setState({checkboxForm:true});
      this.setState({submitButton:true});

      this.setState({question0:"Smart"});
      this.setState({question1:"Very Smart"});
      this.setState({question2:"Genius"});
      this.setState({question3:"Normal"});
    }, this.instructionDelay);
  }

  dataPoint5Initiate(){
    this.instruct("Where were you raised?");
    setTimeout(()=> {
      this.setState({checkboxForm:true});
      this.setState({submitButton:true});

      this.setState({question0:"Small Town"});
      this.setState({question1:"Rural Community"});
      this.setState({question2:"City"});
      this.setState({question3:"Metropolis"});
    }, this.instructionDelay);
  }

  dataPoint6Initiate(){
    this.instruct("What is the best time?");
    setTimeout(()=> {
      this.setState({checkboxForm:true});
      this.setState({submitButton:true});

      this.setState({question0:"Night"});
      this.setState({question1:"Day"});
      this.setState({question2:"Morning"});
      this.setState({question3:"Afternoon"});
    }, this.instructionDelay);
  }

  dataPoint7Initiate(){
    this.instruct("Which describes you best");
    setTimeout(()=> {
      this.setState({checkboxForm:true});
      this.setState({submitButton:true});

      this.setState({question0:"Religious"});
      this.setState({question1:"Secular"});
      this.setState({question2:"Agnostic"});
      this.setState({question3:"Atheist"});
    }, this.instructionDelay);
  }

  dataPoint8Initiate(){
    this.instruct("Thank you for sharing, the algorithm is now determing your perfect match.");
    setTimeout(()=> {
      document.getElementById("instructions").style.opacity="0";
    }, 4000);
    setTimeout(()=> {
      this.props.stage("loveMatch")
    }, 5000);
  }

  instruct(instructions){
    document.getElementById("instructions").style.opacity="0";

    setTimeout(()=> {
      this.setState({instructions: instructions})
      document.getElementById("instructions").style.opacity="1";
    }, 1500);
  
  }

  submitData() {

    let checkBoxes = Array.from( document.getElementsByClassName("checkbox") );
    let checked = false;

    checkBoxes.forEach( (x) => { 
      if(x.checked === true) { checked = true}
    })

    if (checked === true) {
      this.question++;
      this.setState({submitData: true});
        
      switch(this.question){
        case 1  : this.condition1 = true; break;
        case 2  : this.condition2 = true; break;
        case 3  : this.condition3 = true; break;
        case 4  : this.condition4 = true; break;
        case 5  : this.condition5 = true; break;
        case 6  : this.condition6 = true; break;
        case 7  : this.condition7 = true; break;
        case 8  : this.condition8 = true; break;
        default : break;
      }
    }
    
  }

  yesNo(answer){
    this.setState({yesNoAnswer: answer});
  }

  render() {
    
    //TRIGGERS FOR ANSWER AND DATA GIVEN
    if ( (this.state.yesNoAnswer === "yes") && (this.condition0 === true) ) {
      this.condition0 = false;
      this.setState({yesNoAnswer: ""});
      this.setState({yesNo:false});
      this.dataPoint0Initiate();
    }

    if ( (this.state.yesNoAnswer === "no") && (this.condition0 === true) ) {
      this.condition0 = false;
      this.setState({yesNoAnswer: ""});
      this.setState({yesNo:false});
      this.instruct("Please visit when you are ready for true love absolution.");
      setTimeout(()=>{
        document.getElementById("instructions").style.opacity="0";
      }, 5000);
      setTimeout(()=>{
        this.props.stage("video");
      }, 5000);
    }

    if ( (this.state.submitData === true) && (this.condition1 === true) ) {
      this.condition1 = false;
      this.setState({checkboxForm:false});
      this.setState({submitButton:false});
      this.setState({submitData: false});
      localStorage.setItem("question0", this.answer);
      this.dataPoint1Initiate();
    }

    if ( (this.state.submitData === true) && (this.condition2 === true) ) {
      this.condition2 = false;
      this.setState({checkboxForm:false});
      this.setState({submitButton:false});
      this.setState({submitData: false});
      localStorage.setItem("question1", this.answer);
      this.dataPoint2Initiate();
    }

    if ( (this.state.submitData === true) && (this.condition3 === true) ) {
      this.condition3 = false;
      this.setState({checkboxForm:false});
      this.setState({submitButton:false});
      this.setState({submitData: false});
      localStorage.setItem("question2", this.answer);
      this.dataPoint3Initiate();
    }

    if ( (this.state.submitData === true) && (this.condition4 === true) ) {
      this.condition4 = false;
      this.setState({checkboxForm:false});
      this.setState({submitButton:false});
      this.setState({submitData: false});
      localStorage.setItem("question3", this.answer);
      this.dataPoint4Initiate();
    }

    if ( (this.state.submitData === true) && (this.condition5 === true) ) {
      this.condition5 = false;
      this.setState({checkboxForm:false});
      this.setState({submitButton:false});
      this.setState({submitData: false});
      localStorage.setItem("question4", this.answer);
      this.dataPoint5Initiate();
    }

    if ( (this.state.submitData === true) && (this.condition6 === true) ) {
      this.condition6 = false;
      this.setState({checkboxForm:false});
      this.setState({submitButton:false});
      this.setState({submitData: false});
      localStorage.setItem("question5", this.answer);
      this.dataPoint6Initiate();
    }

    if ( (this.state.submitData === true) && (this.condition7 === true) ) {
      this.condition7 = false;
      this.setState({checkboxForm:false});
      this.setState({submitButton:false});
      this.setState({submitData: false});
      localStorage.setItem("question6", this.answer);
      this.dataPoint7Initiate();
    }

    if ( (this.state.submitData === true) && (this.condition8 === true) ) {
      this.condition8 = false;
      this.setState({checkboxForm:false});
      this.setState({submitButton:false});
      this.setState({submitData: false});
      localStorage.setItem("question7", this.answer);
      this.dataPoint8Initiate();
    }

    return (
      <div id="portal">
        <div className="instructionPanel">
          <p id="instructions">{this.state.instructions}</p>
        </div>
        <div className={"frame"}>
          {this.state.checkboxForm === true?
            <form id="checkboxForm">
              <div>
                <input onClick={()=>this.checkbox("question0")} className="checkbox" type="checkbox" id="question0" name="question0"/>
                <label> -{this.state.question0} </label>
              </div>
              <div>
                <input onClick={()=>this.checkbox("question1")} className="checkbox" type="checkbox" id="question1" name="question1"/>
                <label> -{this.state.question1} </label>
              </div>
              <div>
                <input onClick={()=>this.checkbox("question2")} className="checkbox" type="checkbox" id="question2" name="question2"/>
                <label> -{this.state.question2} </label>
              </div>
              <div>
                <input onClick={()=>this.checkbox("question3")} className="checkbox" type="checkbox" id="question3" name="question3"/>
                <label> -{this.state.question3} </label>
              </div>
            </form> : null
          }
          {this.state.submitButton === true?
            <div className="videoMenu submitButton">
              <h1 onClick={()=>this.submitData()}>Submit</h1>
              <span className="menuDivider"/>
            </div> : null
          }
          {this.state.yesNo === true?
            <div className=" videoMenu yesNo">
              <h1 onClick={()=> this.yesNo("yes")}>Yes</h1>
              <span className="menuDivider"/>
              <h1 onClick={()=> this.yesNo("no")}>No</h1>
            </div> : null
          }
        </div>
      </div>
    );
  }
}

export default ZeroCupid;
