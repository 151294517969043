import React, { Component } from 'react';
import '../App.css';

class Video extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loveMatched: false
    }

    this.video = "empty";
    this.pause = false;

  }

  componentDidMount() {
    this.video = document.getElementById("film");

    if(localStorage.getItem("nameFirst") !== null) {
      this.setState({loveMatched: true});
    }
  
  }

  filmEnd() {
    let menuFrame = document.getElementById("menuFrame");
    let filmFrame = document.getElementById("filmFrame");

    filmFrame.style.opacity="0";
    setTimeout(()=> {menuFrame.style.zIndex="1";menuFrame.style.opacity="1";menuFrame.style.filter="blur(0)";}, 1000);
  }

  playFilm() {
    let menuFrame = document.getElementById("menuFrame");
    let filmFrame = document.getElementById("filmFrame");

    menuFrame.style.opacity="0";
    setTimeout(()=> {filmFrame.style.opacity="1";}, 1000);
    setTimeout(()=> {this.video.play();menuFrame.style.zIndex="-1";menuFrame.style.filter="blur(30px)";}, 2000);

  }

  pauseFilm() {
    if (this.pause === false) {
      this.pause = true;
      this.video.pause();
      document.getElementById("film").style.filter="blur(10px)";
    } else {
      this.pause = false;
      document.getElementById("film").style.filter="blur(0px)";
      this.video.play();
    }

  }

  render() {

    return (
      <div id="portal" className="videoPage">
        <div className={"logoBanner"}>
          <img src="assets/horizontal-logoDotless.png" id="headerLogo" />
        </div>
        <div className="frame" id="filmFrame" onClick={()=>this.pauseFilm()}>
          <video id="film" src="assets/ZeroCupid.mp4" onEnded={()=>this.filmEnd()} />
        </div>
        <div className="frame" id="menuFrame">
          <div className="videoMenu">
            
            { this.state.loveMatched === true ?
              <h1 className="blink0" onClick={()=>this.props.stage("loveMatch")}>activate</h1>
              :
              <h1 className="blink0" onClick={()=>this.props.stage("zeroCupid")}>activate</h1>
            }
            
            <span className="menuDivider"/>
            <h1 className="blink0" onClick={()=>this.playFilm()}>play film</h1>
          </div>
          <div className="laurels">
            
            <div className="laurel1">
              <img className="wings" src="assets/Laurels/laurel1/laurelWingL1.png"/>
              <img className="base" src="assets/Laurels/laurel1/laurelBase1.png"/>
            </div>

            <div className="laurel0">
              <img className="wings" src="assets/Laurels/laurel0/laurelWingL0.png"/>
              <img className="base" src="assets/Laurels/laurel0/laurelBase0.png"/>
              <img className="wings" src="assets/Laurels/laurel0/laurelWingR0.png"/>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default Video;
