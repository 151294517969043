import React, { Component } from 'react';

import LoveMatch from './components/LoveMatch.js';
import Video from './components/Video.js';
import ZeroCupid from './components/ZeroCupid.js';

import './App.css';

class App extends Component {

  constructor(props){
  	super(props);
  	this.state = {
      stage: "video"
    };

  }

  stage(stage){
    this.setState({stage: stage});
  }

  stageSelect(stage) {
    switch(stage) {
      case "loveMatch"   :    return <LoveMatch stage={this.stage.bind(this)}/>; break;
      case "video"       :    return <Video stage={this.stage.bind(this)}/>; break;
      case "zeroCupid"   :    return <ZeroCupid stage={this.stage.bind(this)} />; break;
      default :               return <Video stage={this.stage.bind(this)}/>; break;
    }
  }

  render() {
    
    return (
      <div className="app" id={"app"} >

        {this.stageSelect(this.state.stage)}

      </div>
    );
  }
}

export default App;